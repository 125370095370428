import { yupResolver } from "@hookform/resolvers/yup"
import moment from "moment"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { scoresValidation } from "../../../helpers/validation"
import InfoTooltip from "../../../helpers/Tooltip"
import { notifyError } from '../../../helpers/helper'
import { useTranslation } from 'react-i18next';

interface ComplaintFilingScoresProps {
  setScoredModal?: any
  scoresModal?: boolean
  setIsRegisteredInScores: any
}

function ComplaintFilingScores({ scoresModal, setScoredModal, setIsRegisteredInScores }: ComplaintFilingScoresProps) {

  const [isCaseonScores, setisCaseonScores] = useState<string>("no")
  const navigate = useNavigate()
  const [authLetter, setAuthLetter] = useState(false)
  const [authLetterFile, setAuthLetterFile] = useState(null)
  const { t } = useTranslation();

  const [scoreDetails, setScoreDetails] = useState<object>({
    primaryCaseRefId: "",
    primaryCaseRefDate: ""
  })

  const formOptions = {
    resolver: yupResolver(scoresValidation),
  }

  const { register, handleSubmit, formState } = useForm(formOptions)
  const { errors } = formState

  const handleScoreDetails = (e: any) => {
    const { name, value } = e.target;
    setScoreDetails({
      ...scoreDetails,
      [name]: value,
    })
  }

  const handleFileUpload = (e: any) => {
    const file = e.target.files[0]; // Get the first file from the list
    const formData = new FormData();
    formData.append('file', file);
    if (file.size > 100 * 1024 * 1024) {
      notifyError(t('UploadFileExceeds'))
      return false;
    }
    setAuthLetterFile(file)
  }

  const onSubmit = () => {
    if (authLetterFile) {
      setIsRegisteredInScores(scoreDetails, authLetterFile);
    }
    setIsRegisteredInScores(scoreDetails, null)
    setScoredModal(!scoresModal)
  }

  const cancelForm = (e: any) => {
    setScoredModal(!scoresModal)
    navigate('disputes')
  }

  return (
    <div
      className="fixed z-10 inset-0 flex items-center justify-center "
      id="modal"
    >
      <div className="px-8 sm:px-0 sm:w-1/3">
        <div className="flex justify-center pt-4 pb-20 w-fit h-fit">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-900 opacity-75" />
          </div>
          <div
            className="inline-block align-center w-fit bg-white rounded-lg py-4 text-left shadow-xl transform transition-all "
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col gap-1 px-8 pb-5">
                <h1 className='font-semibold sm:text-2xl text-lg pt-3'>{t('TriedResolving')}</h1>
                <div className='flex flex-col gap-6 pb-3 pt-8 text-label-grey'>
                  <div className="flex flex-row gap-3">
                    <div className="flex flex-row gap-2" >
                      <input type="radio" id="Yes" value={"yes"} name="score-options" onChange={(e: any) => {
                        setisCaseonScores("yes")
                      }} />
                      <label htmlFor="Yes" className="text-xs">{t('Yes')}</label>
                    </div>
                    <div className="flex flex-row gap-2">
                      <input type="radio" id="No" checked={isCaseonScores === "no" ? true : false} value="no" name="score-options" onChange={(e: any) => {
                        setisCaseonScores("no")
                      }} />
                      <label htmlFor="No" className="text-xs">{t('No')}</label>
                    </div>
                  </div>
                  {isCaseonScores === 'yes' && (
                    <div className="flex flex-col gap-3  text-label-grey">
                      <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 justify-between items-baseline">
                        <div className="flex flex-row gap-2 items-center">
                          <label htmlFor="Date" className="text-xs">{t('DateOfLodgingPrimaryDispute')}</label>
                          <InfoTooltip title={t('ProvideDate')} />
                        </div>
                        <input type="date" name="primaryCaseRefDate" id="Date" max={moment().format('YYYY-MM-DD')} min={"1970-01-01"} onChange={handleScoreDetails} className="border rounded-lg border-back-gray sm:w-1/3 w-full p-2" />
                      </div>
                      <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 justify-between items-baseline">
                        <div className="flex flex-row gap-2 items-center">
                          <label htmlFor="ReferenceNo" className="text-xs">{t('PrimaryDisputeReferenceNo')}.</label>
                          <InfoTooltip title={t('ProvideReferenceNumber')} />
                        </div>
                        <input {...register('primaryCaseRefId')} type="text" name="primaryCaseRefId" id="ReferenceNo" onChange={handleScoreDetails} className={`border rounded-lg sm:w-1/3 w-full p-2 ${errors.primaryCaseRefId?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
                      </div>
                      <span className="text-xs text-highlight-red">{errors.primaryCaseRefId?.message}</span>
                    </div>
                  )}
                </div>
                {isCaseonScores === 'no' && (<label htmlFor="ReferenceNo" className="text-xs">{t('PleaseFileDisputeDirectly')} </label>)}
                <div className="flex flex-col gap-2 pt-2">
                  <div className="flex flex-row gap-2">
                    <input type="checkbox" onChange={(e) => setAuthLetter(e.target.checked)} />
                    <label className="text-xs">{t('FilingDisputeOnBehalf')}</label>
                  </div>
                  {authLetter &&
                    <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 justify-between items-baseline">
                      <div className="flex flex-row gap-2 items-center">
                        <label htmlFor="ReferenceNo" className="text-xs">{t('UploadAuthorizationLetter')}</label>
                        <InfoTooltip title={t('UploadAuthorizationLetterTooltip')} />
                      </div>
                      <div className="flex flex-row gap-3 items-center border w-1/3 justify-center hover:text-black text-back-gray hover:border-black border-border-grey rounded-lg">
                        <label htmlFor='upload' className="cursor-pointer flex items-center gap-2 p-2">
                          <p className="text-xs ">{t('UploadFile')}</p>
                          <i className="ph-bold ph-paperclip text-sm"></i>
                          <input onChange={handleFileUpload} aria-label={t('Choosefile')} type="file" id='upload' style={{ display: 'none' }} accept=".pdf, .docx" />
                        </label>
                      </div>
                    </div>
                  }
                </div>
                <div className='flex flex-col sm:flex-row w-full pt-12 gap-4'>
                  {isCaseonScores === 'yes' &&
                    <button type="submit" disabled={!(Object.values(scoreDetails).every(value => typeof value === 'string' && value.trim() !== ''))} className="hover:bg-highlight-blue/[0.4] bg-highlight-blue justify-center text-white flex flex-row gap-2 text-xs py-3 rounded-xl disabled:bg-highlight-blue/[0.4] px-12" aria-label={t('Proceed')}
                      onClick={(e) => {
                      }}
                    >
                      <p>{t('Proceed')}</p>
                    </button>
                  }
                  <button aria-label={t('cancel')} onClick={cancelForm} className=" hover:bg-black/[0.1] bg-border-grey  cursor-pointer hover:text-white justify-center text-xs text-white px-12 py-3 rounded-xl">
                    {t('cancel')}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

    </div>
  )
}

export default ComplaintFilingScores