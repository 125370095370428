import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { ArbitrationReport, DisputeArbitrator, User } from '../../../swagger/models';
import { useCombinedStore } from '../../../store';
import PanelOfArbitrators from './PanelOfArbitrators';
import { notifyError } from '../../../helpers/helper';
import ConciliationReportComponent from './ConciliationReport';
import { dateHelper } from '../../common-module/helper';
import moment from 'moment';
import { Tooltip } from 'react-tippy';

interface OutcomeDetailsProps {
  disputeId: any;
  claimAmount: number;
  status: string;
  miiId: number;

}

function OutcomeDetails({ disputeId, claimAmount, status, miiId }: OutcomeDetailsProps) {
  const { t } = useTranslation();
  const [selectedProcess, setSelectedProcess] = useState<string>('Conciliation')
  const [isSegmentDetailsEdit, setIsSegmentDetailsEdit] = useState<boolean>(false)
  const [isProcessdetailsEdit, setIsProcessdetailsEdit] = useState<boolean>(false)
  const [isOutcomedetailsEdit, setIsOutcomedetailsEdit] = useState<boolean>(false)
  const [isCompliancedetailsEdit, setIsCompliancedetailsEdit] = useState<boolean>(false)
  const [isOfficerDetailsEdit, setIsOfficerDetailsEdit] = useState<boolean>(false)
  const [isArbitratorEdit, setIsArbitratorEdit] = useState<boolean>(true)
  const [arbitratorAppointmentModal, setArbitratorAppointmentModal] = useState<boolean>(false)
  const [arbitratorsDetails, setArbitratorsDetails] = useState<DisputeArbitrator[]>([]);
  const [activeTab, setActiveTab] = useState(1);

  const [awardFileUploadDetails, setAwardFileUploadDetails] = useState<any>({
    file: '',
    documentType: '',
    summary: ''
  })
  const [awardUploadModal, setAwardUploadModal] = useState(false)

  const currentURL = window.location.href.split('/')[3]

  const [arbitrationReport, setArbitrationReport] = useState<ArbitrationReport>({
    complainId: disputeId,
  })
  const updateArbitralAward = useCombinedStore((state: any) => state.updateArbitralAward);
  const createArbitralAward = useCombinedStore((state: any) => state.createArbitralAward);
  const createArbitrator = useCombinedStore((state: any) => state.createArbitrator);
  const getArbitralAward = useCombinedStore((state: any) => state.getArbitralAward);
  const getArbitrators = useCombinedStore((state: any) => state.getArbitrators);
  const arbitralFiles = useCombinedStore((state: any) => state.arbitralFiles);
  const arbitralAwardDetails = useCombinedStore((state: any) => state.arbitralAwardDetails);
  const conciliatorAwardDetails = useCombinedStore((state: any) => state.conciliatorAwardDetails);
  const selectedArbitrators = useCombinedStore((state: any) => state.selectedArbitrators);
  const downloadFileOfComplaint = useCombinedStore((state: any) => state.downloadFile)
  const uploadArbitralFile = useCombinedStore((state: any) => state.uploadArbitralFile);
  const listGetUsers = useCombinedStore((state: any) => state.listGetUsers);
  const officers = useCombinedStore((state: any) => state.officers);

  const handleAddRow = () => {
    setArbitratorsDetails([
      ...arbitratorsDetails,
      {
        arbitratorName: '',
        arbitratorRejectionDate: '',
        arbitratorAcceptanceDate: '',
        arbitratorAppointedDate: '',
        complainId: disputeId,
        process: 'Arbitration',
        arbIndex: activeTab
      },
    ]);
  };

  const handleInputChange = (
    index: number,
    field: keyof DisputeArbitrator,
    value: string
  ) => {
    const updatingObject = arbitratorsDetails.filter(arb => arb.arbIndex === activeTab)[index]
    console.log(index, arbitratorsDetails.filter(arb => arb.arbIndex === activeTab))
    const findIndex = arbitratorsDetails.findIndex(arb => arb.arbIndex === activeTab && arb.arbitratorName === updatingObject.arbitratorName)

    const updatedDetails = [...arbitratorsDetails];
    if (field === 'isReappointed') {
      updatedDetails[findIndex].reappointedBehalfOf = arbitratorsDetails.filter(arb => arb.arbIndex === activeTab)[index - 1]?.disputeArbitratorId;
    }
    if (field === 'isApproved') {
      updatedDetails[findIndex].arbitratorRejectionDate = null;
      updatedDetails[findIndex].arbitratorAcceptanceDate = null;
    }
    updatedDetails[findIndex][field] = value;
    setArbitratorsDetails(updatedDetails);
  };

  const saveOutComeDetails = (detailType: string, e: any) => {
    if (e) {
      e.preventDefault();
    }
    if (arbitrationReport.arbitrationReportId) {
      updateArbitralAward(arbitrationReport, detailType)
    } else {
      createArbitralAward(arbitrationReport, detailType)
    }
    setIsOutcomedetailsEdit(false)
    setIsCompliancedetailsEdit(false)
    setIsProcessdetailsEdit(false)
    setIsSegmentDetailsEdit(false)
    setIsOfficerDetailsEdit(false)
  }

  const saveConArbDetails = () => {
    createArbitrator({
      complainId: disputeId,
      DisputeArbitrators: arbitratorsDetails.filter((e: DisputeArbitrator) => e.arbitratorName.length > 2)
    }, 'Arbitration',
    )
    setIsArbitratorEdit(false)
  }

  const captureInputChange = (e: any) => {
    const { name, value } = e.target
    setArbitrationReport({
      ...arbitrationReport,
      [name]: value,
    });
  }

  useEffect(() => {
    setArbitrationReport({
      ...arbitrationReport,
      complainId: disputeId
    })
    if (disputeId) {
      getArbitralAward(disputeId)
      getArbitrators(disputeId)
    }
    if (currentURL === 'mii' || currentURL === 'odr') {
      listGetUsers(miiId)
    }
  }, [disputeId])

  useEffect(() => {
    if (arbitralAwardDetails?.arbitrationReportId) {
      setArbitrationReport(arbitralAwardDetails)
    } else {
      setArbitrationReport({
        ...arbitrationReport,
        officerName: conciliatorAwardDetails.officerName,
        officerId: conciliatorAwardDetails.officerId,
        segment: conciliatorAwardDetails.segment
      })
    }
  }, [arbitralAwardDetails, conciliatorAwardDetails])

  useEffect(() => {
    if (selectedArbitrators?.length > 0) {
      setArbitratorsDetails(selectedArbitrators?.filter((arbitrator: DisputeArbitrator) => arbitrator.process === 'Arbitration'))
    }
  }, [selectedArbitrators])

  const handleUploadAwards = (e: any) => {
    const file = e.target.files;
    let fileSize = 0
    for (const f of file) {
      fileSize += f.size;
    }
    if (fileSize > 100 * 1024 * 1024) {
      notifyError(t('UploadFileExceeds'))
      return false;
    }
    setAwardFileUploadDetails({
      ...awardFileUploadDetails,
      file: file[0],
    })
  }

  const handleRemoveFile = () => {
    setAwardFileUploadDetails({
      ...awardFileUploadDetails,
      file: null,
    });
  }

  const submitFileDetails = (e: any) => {
    e.preventDefault();
    if (!awardFileUploadDetails.file) {
      notifyError(t('PleaseUploadDocument')) 
      return false;
     }
    uploadArbitralFile(awardFileUploadDetails, arbitrationReport?.arbitrationReportId);
    setAwardUploadModal(false)
    handleRemoveFile()
  }

  const downloadFile = async (key: string) => {
    let res = await downloadFileOfComplaint(key, disputeId, null, null, null, arbitrationReport?.arbitrationReportId, null)
    if (!res.error) window.open(res.file)
  }

  const selectOfficer = (e: any) => {
    setArbitrationReport({
      ...arbitrationReport,
      officerName: officers.find((o: User) => o.userId === parseInt(e.target.value, 10)).userName,
      officerId: e.target.value,
    });
  }

  return (
    <div className="hidden sm:flex flex-col gap-4 border-l border-border-grey">
      <div className='pl-8 pb-4'>
        <div className="flex bg-white w-fit rounded-lg border border-border-grey items-center">
          <div onClick={() => setSelectedProcess('Conciliation')} className={`${selectedProcess === 'Conciliation' ? 'flex flex-row gap-2 items-center bg-gray-200 px-2 py-1 font-semibold cursor-pointer text-left sm:text-sm text-xs w-fit sm:py-3 border-r border-border-grey' : 'flex flex-row gap-2 items-center sm:text-sm text-xs w-fit sm:py-3 text-reports-light-grey text-left hover:font-semibold hover:bg-gray-100 hover:shadow-lg transition-all px-2 py-1 cursor-pointer border-r border-border-grey'}`}>
            <p>{t('Conciliation')}</p>
          </div>
          {(status === 'ARBITRATION_REQUESTED' || status === 'ARBITRATION_INITIATED' || status === 'ARBITRATION_IN_PROGRESS' || status === 'DISPUTE_RESOLVED_-_ARBITRATION') &&
            <div onClick={() => setSelectedProcess('Arbitration')} className={`${selectedProcess === 'Arbitration' ? 'flex flex-row gap-2 items-center bg-gray-200 px-2 py-1 font-semibold cursor-pointer text-left sm:text-sm text-xs w-fit sm:py-3 border-l border-border-grey' : 'flex flex-row gap-2 items-center sm:text-sm text-xs w-fit sm:py-3 text-reports-light-grey text-left hover:font-semibold hover:bg-gray-100 hover:shadow-lg transition-all px-2 py-1 cursor-pointer border-l border-border-grey'}`}>
              <p>{t('Arbitration')}</p>
            </div>
          }
        </div>
      </div>
      {selectedProcess === 'Conciliation' ? (
        <ConciliationReportComponent disputeId={disputeId} claimAmount={claimAmount} status={status} miiId={miiId} isEditDisabled={true} />
      ) : (
        <>
          {/* Segment  */}
          {false &&      
          <div className="flex flex-col gap-4 pl-8 pb-4 border-b border-border-grey">
            <div className="flex items-center justify-between">
              <p className="sm:text-sm text-highlight-blue text-xs font-semibold">{t('Segment')}</p>
              <div className='flex gap-2'>
                {(currentURL === 'mii' || currentURL === 'odr') && status !== 'AWAITING_MII_REVIEW' &&
                  <button className="text-xs text-label-grey pl-3 pr-2" onClick={(e: any) => setIsSegmentDetailsEdit(true)}>{t('Edit')}</button>
                }
                {isSegmentDetailsEdit &&
                  <button onClick={(e) => saveOutComeDetails('Segment', e)} className="text-xs text-label-blue pl-3 pr-2">{t('Save')}</button>
                }
              </div>
            </div>
            {!isSegmentDetailsEdit && arbitrationReport?.segment &&
              <span className="bg-white w-fit text-reports-grey rounded-lg text-xs font-semibold sm:text-sm px-4 py-2">{arbitrationReport?.segment}</span>
            }
            {isSegmentDetailsEdit &&
              <div className="flex sm:flex-row items-center flex-col gap-2 sm:justify-between w-full">
                <select onChange={captureInputChange} value={arbitrationReport?.segment}
                  aria-label={'Segment'} required name='segment' id='segment'
                  className="text-xs p-2 w-1/2 border border-back-gray bg-white rounded-xl"

                >
                  <option>{t('SelectSegment')}</option>
                  <option value={'1'}>1</option>
                  <option value={'2'}>2</option>
                </select>
              </div>
            }
          </div>
          }
          {/* Process Details  */}
          <form onSubmit={(e) => saveOutComeDetails('Process Details', e)}>
          <div className="flex flex-col gap-4 pl-8 pb-4 border-b border-border-grey">
            <div className="flex items-center justify-between">
              <p className="sm:text-sm text-xs font-semibold text-highlight-blue">{t('ProcessDetails')}</p>
              <div className='flex gap-2'>
                {(currentURL === 'mii' || currentURL === 'odr') && status !== 'AWAITING_MII_REVIEW' &&
                  <button type='button' className="text-xs text-label-grey pl-3 pr-2" onClick={(e: any) => setIsProcessdetailsEdit(true)}>{t('Edit')}</button>
                }
                {isProcessdetailsEdit &&
                  <button type='submit' className="text-xs text-label-blue pl-3 pr-2">{t('Save')}</button>
                }
              </div>
            </div>
            <>
              <div>
                {!isProcessdetailsEdit &&
                  <div className='flex flex-col gap-4'>
                    <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                      <p className="text-reports-light-grey w-1/2">{t('ClaimAmount')}</p>
                      <p className='text-highlight-blue w-1/2'>₹{claimAmount}</p>
                    </div>
                    {/* <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                      <p className="text-reports-light-grey w-1/2">{t('CounterClaimAmount')}</p>
                      <p className='text-highlight-blue w-1/2'>₹{arbitrationReport?.counterClaimAmount}</p>
                    </div> */}
                    <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                      <p className="text-reports-light-grey w-1/2">{t('NumberofHearings')}</p>
                      <p className='text-highlight-blue w-1/2'>{arbitrationReport?.noOfHearings}</p>
                    </div>
                    <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                      <p className="text-reports-light-grey w-1/2">{t('FeePaidMarketParticipant')}</p>
                      <p className='text-highlight-blue w-1/2'>{(arbitrationReport?.isFeePaidByMP !== null && arbitrationReport?.isFeePaidByMP !== undefined) ? (arbitrationReport?.isFeePaidByMP ? 'Yes' : 'No') : ''}</p>
                    </div>
                    <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                      <p className="text-reports-light-grey w-1/2">{t('DateFeePaidMarketParticipant')}</p>
                      <p className='text-highlight-blue w-1/2'>{dateHelper(arbitrationReport?.mpFeesDate)}</p>
                    </div>
                    <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                      <p className="text-reports-light-grey w-1/2">{t('FeePaidByInvestor')}</p>
                      <p className='text-highlight-blue w-1/2'>{(arbitrationReport?.isFeePaidByInvestor !== null && arbitrationReport?.isFeePaidByInvestor !== undefined) ? (arbitrationReport?.isFeePaidByInvestor ? 'Yes' : 'No') : ''}</p>
                    </div>
                    <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                      <p className="text-reports-light-grey w-1/2">{t('DateOfFeePaidbyInvestor')}</p>
                      <p className='text-highlight-blue w-1/2'>{dateHelper(arbitrationReport?.investorFeesDate)}</p>
                    </div>
                    {/* <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                      <p className="text-reports-light-grey w-1/2">{t('AdmissibleClaimDepositPaidMP')}</p>
                      <p className='text-highlight-blue w-1/2'>{(arbitrationReport?.admissibleClaimDepositPaidMP !== null && arbitrationReport?.admissibleClaimDepositPaidMP !== undefined) ? (arbitrationReport?.admissibleClaimDepositPaidMP ? 'Yes' : 'No') : ''}</p>
                    </div> */}
                    {/* <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                      <p className="text-reports-light-grey w-1/2">{t('DateOfAdmissibleClaimDepositMP')}</p>
                      <p className='text-highlight-blue w-1/2'>{dateHelper(arbitrationReport?.dateOfAdmissibleClaimDepositMP)}</p>
                    </div> */}
                    <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                      <p className="text-reports-light-grey w-1/2">{t('SolePanel')}</p>
                      <p className='text-highlight-blue w-1/2'>{arbitrationReport?.arbitratorType}</p>
                    </div>
                  </div>
                }
              </div>
              <div className="flex flex-col w-full h-fit gap-14 overflow-hidden">
                <div className="flex flex-col gap-4">
                  {isProcessdetailsEdit &&
                    <div className='flex flex-col gap-4'>
                      <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                        <p className="text-sm text-reports-light-grey w-1/2">{t('ClaimAmount')}</p>
                        <input type="text" readOnly value={claimAmount} name="claimAmount" id="claimAmount"
                          className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                      </div>
                      {/* <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                        <p className="text-sm text-reports-light-grey w-1/2">{t('CounterClaimAmount')}</p>
                        <input type="number" step={0.001} min={0} onChange={captureInputChange} value={arbitrationReport?.counterClaimAmount} name="counterClaimAmount" id="counterClaimAmount"
                          className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                      </div> */}
                      <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                        <p className="text-sm text-reports-light-grey w-1/2">{t('NumberofHearings')}</p>
                        <input type="number" min={0} step={1} onChange={captureInputChange} value={arbitrationReport?.noOfHearings} name="noOfHearings" id="noOfHearings"
                          className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                      </div>
                      <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                        <p className="text-sm text-reports-light-grey w-1/2">{t('FeePaidMarketParticipant')}</p>
                        <select onChange={captureInputChange} value={arbitrationReport?.isFeePaidByMP}
                          aria-label={'FeePaid'} required name='isFeePaidByMP' id='isFeePaidByMP'
                          className="text-xs p-2 w-1/2 border border-back-gray bg-white rounded-xl"
                        >
                          <option disabled selected>Select</option>
                          <option value={'true'}>{t('Yes')}</option>
                          <option value={'false'}>{t('No')}</option>
                        </select>
                      </div>
                      <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                        <p className="text-sm text-reports-light-grey w-1/2">{t('DateFeePaidMarketParticipant')}</p>
                        <input type="date" onChange={captureInputChange} value={arbitrationReport?.mpFeesDate} name="mpFeesDate" id="mpFeesDate"
                          className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                      </div>
                      <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                        <p className="text-sm text-reports-light-grey w-1/2">{t('FeePaidByInvestor')}</p>
                        <select onChange={captureInputChange} value={arbitrationReport?.isFeePaidByInvestor}
                          aria-label={'is Fee Paid By Investor'} required name='isFeePaidByInvestor' id='isFeePaidByInvestor'
                          className="text-xs p-2 w-1/2 border border-back-gray bg-white rounded-xl"
                        >
                          <option disabled selected>Select</option>
                          <option value={'true'}>{t('Yes')}</option>
                          <option value={'false'}>{t('No')}</option>
                        </select>
                      </div>
                      <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                        <p className="text-sm text-reports-light-grey w-1/2">{t('DateOfFeePaidbyInvestor')}</p>
                        <input type="date" onChange={captureInputChange} value={arbitrationReport?.investorFeesDate} name="investorFeesDate" id="investorFeesDate"
                          className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                      </div>
                      {/* <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                        <p className="text-sm text-reports-light-grey w-1/2">{t('AdmissibleClaimDepositPaidMP')}</p>
                        <select onChange={captureInputChange} value={arbitrationReport?.admissibleClaimDepositPaidMP}
                          aria-label={'is Fee Paid By Investor'} required name='admissibleClaimDepositPaidMP' id='admissibleClaimDepositPaidMP'
                          className="text-xs p-2 w-1/2 border border-back-gray bg-white rounded-xl"
                        >
                          <option disabled selected>Select</option>
                          <option value={'true'}>{t('Yes')}</option>
                          <option value={'false'}>{t('No')}</option>
                        </select>
                      </div> */}
                      {/* <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                        <p className="text-sm text-reports-light-grey w-1/2">{t('DateOfAdmissibleClaimDepositMP')}</p>
                        <input type="date" onChange={captureInputChange} value={arbitrationReport?.dateOfAdmissibleClaimDepositMP} name="dateOfAdmissibleClaimDepositMP" id="dateOfAdmissibleClaimDepositMP"
                          className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                      </div> */}
                      <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                        <p className="text-sm text-reports-light-grey w-1/2">{t('SolePanel')}</p>
                        <select onChange={captureInputChange} value={arbitrationReport?.arbitratorType}
                          aria-label={t('SolePanel')} required name='arbitratorType' id='arbitratorType'
                          className="text-xs p-2 w-1/2 border border-back-gray bg-white rounded-xl"
                        >
                          <option disabled selected>Select</option>
                          <option value={'Sole'}>{t('Sole')}</option>
                          <option value={'Panel'}>{t('Panel')}</option>
                        </select>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </>

          </div>
          </form>
          {/* Arbitrator details  */}
          <div className='pl-8 border-b border-border-grey pb-4'>
            {arbitrationReport?.arbitratorType === 'Sole' &&
              <>
                {arbitratorsDetails?.map((arbitrator: DisputeArbitrator) => {
                  return <div className='flex flex-col gap-4 pb-2'>
                    {!arbitrator?.isReappointed && <>
                      <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                        <p className="text-reports-light-grey w-1/2">{t('ArbitratorName')}:</p>
                        <p className='text-highlight-blue w-1/2'>{arbitrator?.arbitratorName}</p>
                      </div>
                      <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                        <p className="text-reports-light-grey w-1/2">{t('ArbitratorAppointmentApproval')}:</p>
                        <p className='text-highlight-blue w-1/2'>{(arbitrator?.isApproved !== null && arbitrator?.isApproved !== undefined) ? (arbitrator?.isApproved ? 'Yes' : 'No') : ''}</p>
                      </div>
                      {arbitrator?.arbitratorAppointedDate &&
                        <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                          <p className="text-reports-light-grey w-1/2">{t('ArbitratorAppointmentDate')}:</p>
                          <p className='text-highlight-blue w-1/2'>{dateHelper(arbitrator?.arbitratorAppointedDate)}</p>
                        </div>
                      }
                      {arbitrator?.arbitratorRejectionDate &&
                        <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                          <p className="text-reports-light-grey w-1/2">{t('ArbitratorRejectedDate')}:</p>
                          <p className='text-highlight-blue w-1/2'>{dateHelper(arbitrator?.arbitratorRejectionDate)}</p>
                        </div>
                      }
                      {arbitrator?.arbitratorAcceptanceDate &&
                        <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                          <p className="text-reports-light-grey w-1/2">{t('ArbitratorAcceptanceDate')}:</p>
                          <p className='text-highlight-blue w-1/2'>{dateHelper(arbitrator?.arbitratorAcceptanceDate)}</p>
                        </div>
                      }
                    </>
                    }
                    {arbitrator?.Replacements?.map((replacement: DisputeArbitrator, index: number) => {
                      return <>
                        <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                          <p className="text-reports-light-grey w-1/2">{t('ArbitratorReappointed')}:</p>
                          <p className='text-highlight-blue w-1/2'>Yes</p>
                        </div>
                        <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                          <p className="text-reports-light-grey w-1/2">{t('ArbitratorReappointedName')} {index + 1}:</p>
                          <p className='text-highlight-blue w-1/2'>{replacement?.arbitratorName}</p>
                        </div>
                        <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                          <p className="text-reports-light-grey w-1/2">{t('ArbitratorAcceptanceDate')} {index + 1}:</p>
                          <p className='text-highlight-blue w-1/2'>{replacement?.arbitratorAcceptanceDate}</p>
                        </div>
                      </>
                    })}

                  </div>
                })}
                {(currentURL === 'mii' || currentURL === 'odr') &&
                  <p className="text-sm text-document-blue hover:underline cursor-pointer" onClick={() => { setArbitratorAppointmentModal(true); handleAddRow() }}>{t('EditArbitratorDetails')}</p>
                }
              </>
            }
            {arbitrationReport?.arbitratorType === 'Panel' && (currentURL === 'mii' || currentURL === 'odr') &&
              <div>
                <PanelOfArbitrators arbitrators={arbitratorsDetails}></PanelOfArbitrators>
                <p className="text-sm pl-2 text-document-blue hover:underline cursor-pointer" onClick={() => { setArbitratorAppointmentModal(true); handleAddRow() }}>{t('EditArbitratorDetails')}</p>
              </div>
            }

          </div>

          {/* Outcome details  */}
          <form onSubmit={(e) => saveOutComeDetails('Outcome Details', e)}>
          <div className="flex flex-col gap-4 pl-8 pb-4 border-b border-border-grey">
            <div className="flex items-center justify-between">
              <p className="sm:text-sm text-xs font-semibold text-highlight-blue">{t('OutcomeDetails')}</p>
              <div className='flex gap-2'>
                {(currentURL === 'mii' || currentURL === 'odr') && status !== 'AWAITING_MII_REVIEW' &&
                  <button type='button' className="text-xs text-label-grey pl-3 pr-2" onClick={(e: any) => setIsOutcomedetailsEdit(true)}>{t('Edit')}</button>
                }
                {isOutcomedetailsEdit &&
                  <button type='submit'  className="text-xs text-label-blue pl-3 pr-2">{t('Save')}</button>
                }
              </div>
            </div>
            <>
              <div className="grid grid-cols-2 xl:grid-cols-3 gap-4">
                {!isOutcomedetailsEdit && arbitralFiles?.map((file: any) =>
                  <div className="border flex flex-col gap-3 rounded-lg p-4 bg-white">
                    <h3 className="text-sm text-highlight-blue font-semibold">{file?.documentType}</h3>
                    <p className="text-xs text-low-grey mt-2">
                      {file?.summary?.length > 60 ? `${file.summary.slice(0, 60)}...` : file.summary}
                    </p>
                    <hr />
                    <div onClick={(e) => downloadFile(file?.file)} className='flex gap-2 items-center'>
                      {/* <button className="text-xs text-low-grey w-fit hover:underline">View</button>
                <i className='ph ph-eye text-low-grey'></i> */}
                      <button className="text-xs text-low-grey w-fit hover:underline">{t('Download')}</button>
                      <i className='ph ph-file-arrow-down text-low-grey'></i>
                    </div>
                  </div>
                )}
              </div>
              <div>
                <div className='flex items-center gap-8 pb-4 sm:text-sm w-full text-xs'>
                  <p className="text-reports-light-grey w-1/2">{t('ResolutionTime')}:</p>
                  <p className='text-highlight-blue w-1/2'>32 Days</p>
                </div>
                {!isOutcomedetailsEdit &&
                  <>
                    <div className='flex items-center gap-8 pb-4 sm:text-sm w-full text-xs'>
                      <p className="text-reports-light-grey w-1/2">{t('AwardDate')}</p>
                      <p className='text-highlight-blue w-1/2'>{arbitrationReport?.arbitralAwardDate}</p>
                    </div>
                    <div className='flex items-center gap-8 pb-4 sm:text-sm w-full text-xs'>
                      <p className="text-reports-light-grey w-1/2">{t('AwardReceivedDate')}</p>
                      <p className='text-highlight-blue w-1/2'>{arbitrationReport?.receiptOfArbitralAwardDate}</p>
                    </div>
                    <div className='flex items-center gap-8 pb-4 sm:text-sm w-full text-xs'>
                      <p className="text-reports-light-grey w-1/2">{t('AwardinFavorof')}</p>
                      <p className='text-highlight-blue w-1/2'>{arbitrationReport?.awardInFavor}</p>
                    </div>

                    <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                      <p className="text-reports-light-grey w-1/2">{t('AwardSentDate')}</p>
                      <p className='text-highlight-blue w-1/2'>{arbitrationReport?.arbitralAwardSentDate}</p>
                    </div>
                    <div className='flex items-center gap-8 pt-4 pb-4 sm:text-sm w-full text-xs'>
                      <p className="text-reports-light-grey w-1/2">{t('ArbitralAwardAmount')}</p>
                      <p className='text-highlight-blue w-1/2'>₹{arbitrationReport?.arbitralAwardAmount}</p>
                    </div>
                  </>
                }
              </div>
              <div className="flex flex-col w-full h-fit gap-14 overflow-hidden">
                <div className="flex flex-col gap-4">
                  {isOutcomedetailsEdit &&
                    <>
                      <div className="grid grid-cols-2 xl:grid-cols-3 gap-4">
                        {arbitralFiles?.map((file: any) =>
                          <div className="border flex flex-col gap-3 rounded-lg p-4 bg-white">
                            <h3 className="text-sm text-highlight-blue font-semibold">{file?.documentType}</h3>
                            <p className="text-xs text-low-grey mt-2">
                              {file?.summary?.length > 60 ? `${file.summary.slice(0, 60)}...` : file.summary}
                            </p>
                            <hr />
                            <div onClick={(e) => downloadFile(file?.file)} className='flex gap-2 items-center'>
                              <button className="text-xs text-low-grey w-fit hover:underline">{t('Download')}</button>
                              <i className='ph ph-file-arrow-down text-low-grey'></i>
                            </div>
                          </div>
                        )}
                        <label htmlFor='upload-award' className="flex flex-col gap-1">
                          <Tooltip
                            html={
                              <div className=" text-left flex flex-col gap-1">
                                <p className="">{t('AddDocuments')}</p>
                              </div>
                            }
                            position="top"
                            arrow={false}
                            animation="shift"
                            size="small"
                            {...({
                              children: <i className="text-base text-highlight-blue cursor-pointer hover:text-highlight-blue/50 border border-highlight-blue w-fit px-2 py-1 items-center flex justify-center ph-bold ph-plus"
                                onClick={(e) => {
                                  e.preventDefault(); // Stop the form from submitting
                                  setAwardUploadModal(true);
                                }} style={{ borderRadius: '50%' }}></i>
                            } as any)}
                          >
                            {/* <i className="text-base text-highlight-blue cursor-pointer hover:text-highlight-blue/50 border border-highlight-blue w-fit px-2 py-1 items-center flex justify-center ph-bold ph-plus" style={{ borderRadius: '50%' }}></i> */}
                            {/* <button onClick={() => setAwardUploadModal(true)} id="upload-award" style={{ display: 'none' }} /> */}
                          </Tooltip>

                        </label>
                      </div>
                      <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                        <p className="text-sm text-reports-light-grey w-1/2">{t('AwardDate')}</p>
                        <input type="date" onChange={captureInputChange} value={arbitrationReport?.arbitralAwardDate} name="arbitralAwardDate" id="arbitralAwardDate" className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                      </div>
                      <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                        <p className="text-sm text-reports-light-grey w-1/2">{t('AwardReceivedDate')}</p>
                        <input type="date" onChange={captureInputChange} value={arbitrationReport?.receiptOfArbitralAwardDate} name="receiptOfArbitralAwardDate" id="receiptOfArbitralAwardDate" className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                      </div>
                      <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                        <p className="text-sm text-reports-light-grey w-1/2">{t('AwardinFavorof')}</p>
                        <select onChange={captureInputChange} value={arbitrationReport?.awardInFavor}
                          aria-label={'AwardinFavorof'} required name='awardInFavor' id='awardInFavor'
                          className="text-xs p-2 w-1/2 border border-back-gray bg-white rounded-xl"
                        >
                          <option>{t('Select')}</option>
                          <option value={'Market Participant'}>{t('MarketParticipant')}</option>
                          <option value={'Investor'}>{t('Investor')}</option>
                        </select>
                      </div>
                      <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                        <p className="text-sm text-reports-light-grey w-1/2">{t('AwardSentDate')}</p>
                        <input type="date" onChange={captureInputChange} value={arbitrationReport?.arbitralAwardSentDate} name="arbitralAwardSentDate" id="arbitralAwardSentDate" className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                      </div>
                      <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                        <p className="text-sm text-reports-light-grey w-1/2">{t('ArbitralAwardAmount')}</p>
                        <input type="number" min={0} step="0.001" onChange={captureInputChange} value={arbitrationReport?.arbitralAwardAmount} name="arbitralAwardAmount" id="arbitralAwardAmount" className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                      </div>
                    </>
                  }
                </div>
              </div>
            </>
          </div>
          </form>

          {/* Compliance Status  */}
          <form onSubmit={(e) => saveOutComeDetails('Compliance Status', e)}>
          <div className="flex flex-col gap-4 pb-4 pl-8 border-b border-border-grey">
            <div className="flex items-center justify-between">
              <p className="sm:text-sm text-xs font-semibold text-highlight-blue">{t('ComplianceStatus')}</p>
              <div className='flex gap-2'>
                {(currentURL === 'mii' || currentURL === 'odr') && status !== 'AWAITING_MII_REVIEW' &&
                  <button type='button' className="text-xs text-label-grey pl-3 pr-2" onClick={(e: any) => setIsCompliancedetailsEdit(true)}>{t('Edit')}</button>
                }
                {isCompliancedetailsEdit &&
                  <button type='submit' className="text-xs text-label-blue pl-3 pr-2">{t('Save')}</button>
                }
              </div>
            </div>
            <>
              {!isCompliancedetailsEdit &&
                <>
                  {arbitrationReport?.complianceStatus &&
                    <span className="bg-white w-fit border border-reports-border text-reports-grey rounded-lg text-xs sm:text-sm px-4 py-2">{arbitrationReport?.complianceStatus}</span>
                  }
                  <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                    <p className="text-reports-light-grey w-1/2">{t('DateofCompliance')}</p>
                    <p className='text-highlight-blue w-1/2'>{arbitrationReport?.complianceDate}</p>
                  </div>
                  {arbitrationReport?.nonImplementationReason &&
                    <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                      <p className="text-reports-light-grey w-1/2">{t('ReasonForNonImplementation')}:</p>
                      <p className='text-highlight-blue w-1/2'>{arbitrationReport?.nonImplementationReason}</p>
                    </div>
                  }
                  <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                    <p className="text-reports-light-grey w-1/2">{t('34IntentionReceived')}:</p>
                    <p className='text-highlight-blue w-1/2'>{(arbitrationReport?.isIntentionReceived !== null && arbitrationReport?.isIntentionReceived !== undefined) ? (arbitrationReport?.isIntentionReceived ? 'Yes' : 'No') : ''}</p>
                  </div>
                  {arbitrationReport?.intentionReceivedDate &&
                    <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                      <p className="text-reports-light-grey w-1/2">{t('DateofIntentionReceived')}:</p>
                      <p className='text-highlight-blue w-1/2'>{arbitrationReport?.intentionReceivedDate}</p>
                    </div>
                  }
                </>
              }
              {isCompliancedetailsEdit &&
                <div className="flex flex-col w-full h-fit gap-14 overflow-hidden">
                  <div className="flex flex-col gap-4">
                    <div className="flex sm:flex-row flex-col gap-2 justify-between w-full items-center">
                      <p className="text-sm text-reports-light-grey w-1/2">{t('Compliedby')}:</p>
                      <select
                        aria-label={t('Compliedby')} required name='complianceStatus' id='complianceStatus'
                        className="sm:w-full text-xs p-2 border border-back-gray bg-white rounded-xl"
                        value={arbitrationReport?.complianceStatus}
                        onChange={captureInputChange}
                      >
                        <option disabled selected>Select</option>
                        <option value={'Complied by Market Participant'}>{t('CompliedbyMarketParticipant')}</option>
                        <option value={'Complied by Investor'}>{t('CompliedbyInvestor')}</option>
                        <option value={'Not Complied by Market Participant'}>{t('NotCompliedByMarketParticipant')}</option>
                        <option value={'Not Complied by Investor'}>{t('NotCompliedByInvestor')}</option>
                      </select>
                    </div>
                    <div className="flex sm:flex-row flex-col gap-2 items-center justify-between w-full">
                      <p className="text-sm text-reports-light-grey">{t('DateofImplementation')}:</p>
                      <input type="date" onChange={captureInputChange} value={arbitrationReport?.complianceDate} name="complianceDate" id="complianceDate" className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                    </div>
                    {(arbitrationReport?.complianceStatus === 'Not Complied by Market Participant' || arbitrationReport?.complianceStatus === 'Not Complied by Investor') &&
                      <div className="flex sm:flex-row flex-col gap-2 items-center justify-between w-full">
                        <p className="text-sm text-reports-light-grey">{t('ReasonForNonImplementation')}:</p>
                        <textarea onChange={captureInputChange} name="nonImplementationReason" id="nonImplementationReason" value={arbitrationReport?.nonImplementationReason}
                          className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                      </div>
                    }
                  </div>
                  <div className="flex flex-col w-full h-fit gap-14">
                    <div className="flex flex-col gap-4">
                      <div className="flex sm:flex-row flex-col gap-2 items-center justify-between w-full">
                        <p className="text-sm text-reports-light-grey">{t('34IntentionReceived')}?:</p>
                        <select
                          aria-label={t('34IntentionReceived')} required name='isIntentionReceived' id='isIntentionReceived'
                          className="text-xs p-2 border border-back-gray w-1/2 bg-white rounded-xl"
                          value={arbitrationReport?.isIntentionReceived}
                          onChange={(event: any) => setArbitrationReport({
                            ...arbitrationReport,
                            isIntentionReceived: event.target.value
                          })}
                        >
                          <option>{t('Select')}</option>
                          <option value={'true'}>{t('Yes')}</option>
                          <option value={'false'}>{t('No')}</option>
                        </select>
                      </div>
                      <div className="flex sm:flex-row flex-col gap-2 items-center justify-between w-full">
                        <p className="text-sm text-reports-light-grey w-1/2">{t('DateofIntentionReceived')}:</p>
                        <input type="date" onChange={captureInputChange} value={arbitrationReport?.intentionReceivedDate} name="intentionReceivedDate" id="intentionReceivedDate"
                          className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                      </div>
                    </div>
                  </div>
                </div>
              }
            </>

          </div>
          </form>

          {/* Officer Details  */}
          <form onSubmit={(e) => saveOutComeDetails('Officer Name', e)}>
          <div className="flex flex-col gap-4 pl-8 pb-4">
            <div className="flex items-center justify-between">
              <p className="sm:text-sm text-highlight-blue text-xs font-semibold">{t('OfficerName')}</p>
              <div className='flex gap-2'>
                {(currentURL === 'mii' || currentURL === 'odr') && status !== 'AWAITING_MII_REVIEW' &&
                  <button type='button' className="text-xs text-label-grey pl-3 pr-2" onClick={(e: any) => setIsOfficerDetailsEdit(true)}>{t('Edit')}</button>
                }
                {isOfficerDetailsEdit &&
                  <button type='submit' className="text-xs text-label-blue pl-3 pr-2">{t('Save')}</button>
                }
              </div>
            </div>
            {!isOfficerDetailsEdit && arbitrationReport?.officerName &&
              <span className="bg-white w-fit text-reports-grey rounded-lg text-xs font-semibold sm:text-sm px-4 py-2">{arbitrationReport?.officerName}</span>
            }
            {isOfficerDetailsEdit &&
              <div className="flex sm:flex-row items-center flex-col gap-2 sm:justify-between w-full">
                <select
                  aria-label={' Select Officer Name'} required
                  name='officerName' id='officerName'
                  className="text-xs p-2 w-1/2 border border-back-gray bg-white rounded-xl"
                  onChange={selectOfficer}
                >
                  <option selected disabled>{t('SelectOfficer')}</option>
                  {officers?.map((officer: User) =>
                    <option value={officer?.userId}>{officer?.userName}</option>
                  )}
                </select>
              </div>
            }
          </div>
          </form>
          {awardUploadModal &&
            (
              <div
                className="fixed z-10 inset-0 flex items-center justify-center "
                id="modal"
              >
                <div className="px-8 sm:px-0 sm:w-1/2">
                  <div className="flex justify-center pt-4 pb-20 w-full h-fit">
                    <div
                      className="fixed inset-0 transition-opacity"
                      onClick={() => setAwardUploadModal(false)}
                    >
                      <div className="absolute inset-0 bg-gray-900 opacity-75" />
                    </div>
                    <div
                      className="inline-block align-center w-full bg-white rounded-lg py-4 text-left shadow-xl transform transition-all "
                      role="dialog"
                      aria-modal="true"
                      aria-labelledby="modal-headline"
                    >
                      <form onSubmit={submitFileDetails}>
                        <div className="flex flex-col gap-4 px-8 py-5">
                          <div className='flex gap-4 items-center'>
                            <i className='ph ph-buildings text-2xl px-2 py-1 border border-border-grey rounded-md'></i>
                            <div className='flex flex-col w-full'>
                              <div className='flex justify-between w-full items-center'>
                                <p className='font-semibold sm:text-lg text-sm'>{t('AddOutcomeDocument')}</p>
                                <i onClick={() => { setAwardUploadModal(false); handleRemoveFile() }} className='ph ph-x hover:text-modal-grey cursor-pointer'></i>
                              </div>
                              <p className='text-xs text-modal-grey'>{t('AttachConArbReport')}</p>
                            </div>
                          </div>
                          <hr />
                          <div className='flex flex-col gap-6 text-label-grey'>
                            <div className="flex sm:flex-row flex-col gap-2 sm:gap-8 justify-between items-baseline">
                              <p className="text-sm font-medium">{t('DocumentType')}*</p>
                              <select
                                aria-label={'Settlement Agreement Executed'} required name='documentType' id='documentType'
                                className="text-xs p-2 w-1/2 border border-back-gray bg-white rounded-xl"
                                onChange={(e) => {
                                  setAwardFileUploadDetails({
                                    ...awardFileUploadDetails,
                                    documentType: e.target.value
                                  })
                                }} value={awardFileUploadDetails?.documentType}
                              >
                                <option selected>Select</option>
                                <option value={'Arbitration Award'}>{t('ArbitrationAward')}</option>
                                <option value={'Redacted Award'}>{t('RedactedAward')}</option>
                              </select>
                            </div>
                            <hr />
                            <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 justify-between items-baseline">
                              <div className="flex flex-row gap-2 items-center">
                                <label htmlFor="ReferenceNo" className="sm:whitespace-nowrap text-sm font-medium">{t('UploadFile')}*</label>
                              </div>
                              <div className="flex flex-row gap-3 items-center w-2/3 justify-end rounded-lg">
                                <div className="flex gap-2 items-center">
                                  <div className="flex p-2.5 bg-base-gray" style={{ borderRadius: '50%' }}>
                                    <i className="ph text-4xl ph-image-square"></i>
                                  </div>
                                  <label htmlFor="fileInput" className="cursor-pointer">
                                    <div className="w-full 
                                      border
                                      border-dashed 
                                      border-border-secondary py-4
                                      rounded-lg
                                      flex 
                                      flex-col gap-3
                                      items-center px-4
                                      justify-center border-border-secondary">
                                      <div className="flex flex-col gap-1 items-center">
                                        <p className="text-xs sm:whitespace-nowrap"><span className="text-document-blue font-semibold">{t('ClickToUpload')}</span> {t('orDragAndDrop')}</p>
                                        <p className="text-xs sm:whitespace-nowrap">{t('FileFormat')}</p>
                                      </div>
                                    </div>

                                  </label>
                                  <input aria-label={t('Choosefile')} id="fileInput" type="file" style={{ display: 'none' }} accept={".pdf, .docx"}
                                    onChange={handleUploadAwards} /> {/* Call handleFileChange on file change */}
                                </div>

                              </div>
                            </div>
                            {/* Display Uploaded File Names */}
                            {awardFileUploadDetails?.file && (
                              <div className="flex flex-wrap justify-end gap-2">
                                <span className="bg-gray-200 text-xs px-2 py-1 rounded-full">
                                  {awardFileUploadDetails.file.name}
                                  <span onClick={() => handleRemoveFile()} className="ml-2 cursor-pointer text-red-500">×</span>
                                </span>
                              </div>
                            )}
                            <hr />
                            <div className="flex sm:flex-row flex-col gap-2 sm:gap-8 justify-between items-baseline">
                              <label htmlFor="Summary" className="text-sm font-medium">{t('Summary')}*</label>
                              <textarea name="summary" onChange={(e) => {
                                setAwardFileUploadDetails({
                                  ...awardFileUploadDetails,
                                  summary: e.target.value
                                })
                              }} required placeholder={t('Add10Keywords')} id="Summary" className={`border rounded-lg w-3/4 text-sm p-2 border-border-grey`} />
                            </div>
                            <hr />
                          </div>
                          <div className='flex flex-col sm:flex-row w-full gap-4'>
                            <button aria-label={t('cancel')} onClick={() => setAwardUploadModal(false)} className=" hover:bg-base-gray w-1/2 border-border-grey border cursor-pointer hover:text-white font-semibold justify-center text-sm text-modal-grey px-12 py-3 rounded-xl">
                              {t('cancel')}
                            </button>
                            <button type="submit" className="hover:bg-document-blue/[0.4] w-1/2 bg-document-blue justify-center text-white flex flex-row gap-2 text-sm py-3 rounded-xl disabled:bg-document-blue/[0.4] px-12" aria-label={t('Proceed')}
                              onClick={(e) => {
                              }}
                            >
                              <p>{t('AddDocument')}</p>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

              </div>
            )
          }
          {/* Abritrator Appointment Modal  */}
          {arbitratorAppointmentModal &&
            (
              <div
                className="fixed z-10 inset-0 flex items-center justify-center "
                id="modal"
              >
                <div className="px-8 sm:px-0 sm:w-2/3">
                  <div className="flex justify-center pt-4 pb-20 w-full h-fit">
                    <div
                      className="fixed inset-0 transition-opacity"
                      onClick={() => setArbitratorAppointmentModal(false)}
                    >
                      <div className="absolute inset-0 bg-gray-900 opacity-75" />
                    </div>
                    <div
                      className="inline-block align-center w-full bg-white rounded-lg py-4 text-left shadow-xl transform transition-all h-[36rem] overflow-auto"
                      role="dialog"
                      aria-modal="true"
                      aria-labelledby="modal-headline"
                    >
                      <form>
                        <div className="flex flex-col gap-4 px-8 py-5 overflow-auto">
                          <div className="flex flex-col w-full">
                            <div className="flex justify-between w-full items-center">
                              <p className="font-bold text-highlight-blue sm:text-lg text-sm">
                                {t('AbritratorAppointmentDetails')}
                              </p>
                              <div className="flex gap-4 items-center">
                                <button
                                  type="button"
                                  onClick={() => setIsArbitratorEdit(!isArbitratorEdit)}
                                  className="text-reports-light-grey text-xs hover:underline"
                                >
                                  {isArbitratorEdit ? t('cancel') : t('Edit')}
                                </button>
                                <button
                                  type="button"
                                  onClick={() => saveConArbDetails()}
                                  className="text-reports-light-grey text-xs hover:underline"
                                >
                                  {isArbitratorEdit ? t('Save') : ''}
                                </button>
                              </div>
                            </div>
                            <p className="text-xs w-1/2 text-modal-grey">
                              {t('ViewEditAbritrators')}
                            </p>
                          </div>
                          <table className="table-auto w-full">
                            <thead>
                              <tr>
                                <th className="p-3 whitespace-nowrap rounded-tl-lg rounded-bl-lg bg-base-gray font-normal text-left text-sm text-label-grey">
                                  {t('ArbitratorName')} {activeTab}
                                </th>
                                <th className="p-3 bg-base-gray font-normal text-left text-sm text-label-grey">
                                  {t('DateOfAppointment')}
                                </th>
                                <th className="p-3 bg-base-gray font-normal text-left text-sm text-label-grey">
                                  {t('AbritratorApproved')}
                                </th>
                                <th className="p-3 bg-base-gray font-normal text-left text-sm text-label-grey">
                                  {t('ArbitratorReappointed')}?
                                </th>

                                <th className="p-3 bg-base-gray font-normal text-left text-sm text-label-grey">
                                  {t('DateOfRejection')}
                                </th>
                                <th className="p-3 bg-base-gray font-normal text-left text-sm text-label-grey">
                                  {t('DateOfApproval')}
                                </th>
                                <th className="p-3 rounded-tr-lg rounded-br-lg bg-base-gray font-normal text-left text-sm text-label-grey">
                                  {' '}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {arbitratorsDetails?.filter((detail: DisputeArbitrator) => detail.arbIndex === activeTab).map((detail, index) => (
                                <tr key={index}>
                                  <td className="border-b p-3 font-normal text-xs">
                                    {isArbitratorEdit ? (
                                      <input
                                        type="text"
                                        value={detail.arbitratorName}
                                        onChange={(e) =>
                                          handleInputChange(index, 'arbitratorName', e.target.value)
                                        }
                                        className="text-xs py-3 sm:w-72 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg"
                                      />
                                    ) : (
                                      <span>{detail.arbitratorName}</span>
                                    )}
                                  </td>
                                  <td className="border-b p-3 font-normal text-xs">
                                    {isArbitratorEdit ? (
                                      <input
                                        type="date"
                                        value={detail.arbitratorAppointedDate}
                                        onChange={(e) =>
                                          handleInputChange(index, 'arbitratorAppointedDate', e.target.value)
                                        }
                                        className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg"
                                      />
                                    ) : (
                                      <span>{dateHelper(detail.arbitratorAppointedDate)}</span>
                                    )}
                                  </td>
                                  <td className="border-b p-3 font-normal text-xs">
                                    {isArbitratorEdit ? (
                                      <select onChange={(e) =>
                                        handleInputChange(index, 'isApproved', e.target.value)
                                      } value={detail.isApproved}
                                        aria-label={'IsApproved'} required name='isApproved' id='isApproved'
                                        className="text-xs py-3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg"
                                      >
                                        <option selected disabled>{t('Select')}</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                      </select>
                                    ) : (
                                      <span>{detail.isApproved && detail.isApproved !== null ? (detail.isApproved ? 'Yes' : 'No') : ''}</span>
                                    )}
                                  </td>
                                  <td className="border-b p-3 font-normal text-xs">
                                    {isArbitratorEdit && index !== 0 ? (
                                      <select onChange={(e) =>
                                        handleInputChange(index, 'isReappointed', e.target.value)
                                      }
                                        value={detail.isReappointed}
                                        aria-label={'IsReappointed'} required name='isReappointed' id='isReappointed'
                                        className="text-xs py-3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg"
                                      >
                                        <option selected disabled>{t('Select')}</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                      </select>
                                    ) : (
                                      <span>{detail.isReappointed && detail.isReappointed !== null ? (detail.isReappointed ? 'Yes' : 'No') : ''}</span>
                                    )}
                                  </td>
                                  <td className="border-b p-3 font-normal text-xs">
                                    {isArbitratorEdit ? (
                                      <input
                                        type="date"
                                        value={detail.arbitratorRejectionDate}
                                        min={detail?.arbitratorAppointedDate}
                                        max={new Date().toString()}
                                        disabled={!(detail.isApproved === 'No')}
                                        onChange={(e) =>
                                          handleInputChange(index, 'arbitratorRejectionDate', e.target.value)
                                        }
                                        className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg"
                                      />
                                    ) : (
                                      <span>{dateHelper(detail.arbitratorRejectionDate)}</span>
                                    )}
                                  </td>
                                  <td className="border-b p-3 font-normal text-xs">
                                    {isArbitratorEdit ? (
                                      <input
                                        type="date"
                                        value={detail.arbitratorAcceptanceDate}
                                        min={detail?.arbitratorAppointedDate}
                                        max={new Date().toString()}
                                        disabled={!(detail.isApproved === 'Yes')}
                                        onChange={(e) =>
                                          handleInputChange(index, 'arbitratorAcceptanceDate', e.target.value)
                                        }
                                        className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg"
                                      />
                                    ) : (
                                      <span>{dateHelper(detail.arbitratorAcceptanceDate)}</span>
                                    )}
                                  </td>
                                  <td className="border-b p-3 font-normal text-xs">
                                    <i className="ph ph-dots-three-vertical"></i>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          {isArbitratorEdit && (
                            <div className="flex w-full justify-end">
                              <button
                                type="button"
                                onClick={handleAddRow}
                                className="text-reports-light-grey text-xs hover:underline"
                              >
                                + {t('AddRow')}
                              </button>
                            </div>
                          )}
                        </div>
                      </form>
                      {arbitrationReport?.arbitratorType === 'Panel' &&
                        <div className='flex gap-2 items-center w-full justify-center'>
                          <div onClick={() => setActiveTab(1)} className={`w-2.5 h-2.5 transition-all duration-300 origin-left cursor-pointer ${activeTab === 1 ? 'bg-document-blue' : 'bg-base-gray'}`} style={{ borderRadius: '50%' }}>{" "}</div>
                          <div onClick={() => setActiveTab(2)} className={`w-2.5 h-2.5 transition-all duration-300 origin-left cursor-pointer ${activeTab === 2 ? 'bg-document-blue' : 'bg-base-gray'}`} style={{ borderRadius: '50%' }}>{" "}</div>
                          <div onClick={() => setActiveTab(3)} className={`w-2.5 h-2.5 transition-all duration-300 origin-left cursor-pointer ${activeTab === 3 ? 'bg-document-blue' : 'bg-base-gray'}`} style={{ borderRadius: '50%' }}>{" "}</div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        </>
      )}
    </div>
  );
}

export default OutcomeDetails